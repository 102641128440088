<!--
 * @Author: 月魂
 * @Date: 2021-03-24 16:12:52
 * @LastEditTime: 2021-04-28 18:01:03
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \stoms-mp\src\views\CardBag.vue
-->
<template>
  <div class="box">
    <van-nav-bar
      title="我的卡包"
      fixed
      placeholder
    >
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="jumpHome" />
      </template>
    </van-nav-bar>
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="list.length === 0 ? '暂无数据' : '没有更多了'"
      @load="onCardLoad"
    >
      <div v-for="item in list" :key="item.id" class="cardItem">
        <div class="card">
          <img :src="item.category_image_url" class="cardImg">
        </div>
        <div class="tips">
          <div class="left">
            <div class="number">卡号：{{ item.number }}</div>
            <div class="status">状态：{{ statusMap[item.status] }}</div>
            <div class="last" v-if="item.category_type === 1">剩余次数：{{ item.category_surplus_times }} 次</div>
            <div class="last" v-else>到期时间：{{ item.expiration_time }}</div>
            <!-- <div class="amount">余额￥388.00 / 面额￥388.00</div> -->
            <!-- <div class="time">2021-03-31截止</div> -->
          </div>
          <div class="right">
            <img src="../assets/code.svg" @click="() => showCode(item)">
          </div>
        </div>
      </div>
    </van-list>
    <van-action-sheet v-model="show" title="会员卡二维码">
      <div class="code">
        <div class="number">卡号： {{showData.number}}</div>
        <qrcode-vue :value="qrcode" :size="size" level="H" />
        <div class="msg">可凭此二维码核销</div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import Vue from 'vue';
import { getCardBag, getCardCode } from '@/api/card'
import QrcodeVue from 'qrcode.vue'
import { NavBar, List, ActionSheet, Icon } from 'vant';
Vue.use(NavBar).use(List).use(ActionSheet).use(Icon);

export default {
  name: 'CardBag',
  components: {
    QrcodeVue
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: true,
      show: false,
      showData: {},
      pagination: {
        pageSize: 20,
        pageNumber: 1
      },
      qrcode: '',
      size: 105,
      statusMap: {
        0: '失效',
        1: '有效'
      }
    };
  },
  mounted() {
    this.loading = true
    getCardBag(this.pagination).then(res => {
      if (res.code !== 0) return
      if (res.data.current >= res.data.pages) {
        this.finished = true
      } else {
        this.pagination.pageNumber++
        this.finished = false
      }
      this.list = res.data.records
      this.loading = false
    })
  },
  methods: {
    jumpHome() {
      this.$router.replace('/')
    },
    onCardLoad() {
      if (!this.finished) {
        this.loading = true
        getCardBag(this.pagination).then(res => {
          if (res.code !== 0) return
          if (res.data.current === res.data.pages) {
            this.finished = true
            if (res.data.records.length !== 0) {
              this.list.push(...res.data.records)
            }
          } else {
            this.pagination.pageNumber++
            this.list.push(...res.data.records)
          }
          this.loading = false
        })
      }
      
    },
    showCode(data) {
      getCardCode(data.id).then(res => {
        if (res.code === 0) {
          this.qrcode = res.data.random_code
          this.show = true
          this.showData = data
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100%;
  background-color: #f4f4f4;
  .cardItem {
    width: calc(~"100% - 20px");
    margin: 10px auto;
    padding: 10px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 20px;
    .card {
      box-sizing: border-box;
      width: 335px;
      border-radius: 20px;
      overflow: hidden;
      .cardImg {
        display: block;
        width: 100%;
        height: 150px;
      }
    }
    .tips {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      .left {
        width: 80%;
        display: flex;
        flex-wrap: wrap;
        .number {
          width: 65%;
          font-size: 12px;
          color: #555;
          line-height: 30px;
        }
        .status {
          width: 35%;
          font-size: 12px;
          color: #555;
          line-height: 30px;
        }
        .last{
          font-size: 12px;
          color: #555;
          line-height: 30px;
        }
        .amount {
          width: 65%;
          font-size: 12px;
          color: #555;
          line-height: 30px;
        }
        .time {
          width: 35%;
          font-size: 12px;
          color: #555;
          line-height: 30px;
        }
      }
      .right {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .code {
    padding: 10px 10px 30px;
    text-align: center;
    .number {
      font-size: 14px;
      color: #555;
      line-height: 2;
    }
    img {
      width: 30%;
      margin: 30px auto;
    }
    .msg {
      font-size: 14px;
      color: #555;
      line-height: 2;
    }
  }
}
</style>